<template>
    <b-card style="padding: 16px;">
        <b-row style="width: 100%;">
            <b-table
                style="width: 100%;"
                id="duplicateSubMembersTable"
                ref="duplicateSubMembersTable"
                :fields="headers"
                :items="subMembers"
                :per-page="perPage"
                :current-page="currentPage"
                head-variant="light"
                sticky-header
                class="mh-100"
                selectable
                selected-variant=""
                no-select-on-click
                @row-selected="onRowSelected"
            >
                <template #cell(phone)="row">
                    <b-form-input
                      id="dup-phone"
                      v-model="row.item.phone"
                    />
                </template>
                <template #cell(email)="row">
                    <b-form-input
                      id="dup-email"
                      v-model="row.item.email"
                    />
                </template>

                <template #cell(actions)="row">
                <div class="justify-content-end d-flex">
                    <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm"
                    @click="saveMemberClicked(row.item)"
                    >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="/assets/svg/disk.svg"></inline-svg>
                        <!--end::Svg Icon-->
                    </span>
                    </a>

                    <a class="btn btn-icon btn-light btn-sm mx-2" @click="gotoMemberClicked(row.item.member_id)">
                    <span class="svg-icon svg-icon-md text-primary">
                        <i class="fa fa-user color-primary"></i>
                    </span>
                    </a>

                    <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm"
                    @click="deleteMemberClicked(row.item.member_id)"
                    >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                        <!--end::Svg Icon-->
                    </span>
                    </a>
                </div>
                </template>
                <template #head(selected)>
                <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input type="checkbox" name="" :checked="allSelected" @click="toggleRows" />
                    <span></span>
                    </label>
                </div>
                </template>
                <template #cell(selected)="data">
                <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                        type="checkbox"
                        name=""
                        :checked="data.rowSelected"
                        @click="selectRow(data.index)"
                    />
                    <span></span>
                    </label>
                </div>
                </template>

            </b-table>
        </b-row>

    </b-card>

</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'DuplicatesSubMembersTable',
  components: {

  },
  props: ['subMembers','duplicateSummaryItem','showConfirm'],
  emits: ['onDeleteSubMember'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),
  },
  mixins: [ toasts ],
  mounted() {
    const loader = this.$loading.show();


    loader & loader.hide();
  },
  data() {
    return {
      headers: [
        {
          key: 'selected',
          label: 'Vald',
          sortable: false
        },
        {
          key: 'public_id',
          label: '#',
          sortable: true
        },
        {
          key: 'firstname',
          label: 'Förnamn',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Efternamn',
          sortable: true
        },
        {
          key: 'email',
          label: 'E-post',
          sortable: true
        },
        {
          key: 'phone',
          label: 'Telefon',
          sortable: true
        },
        {
          key: 'rdt',
          label: 'Registrering',
          sortable: true
        },
        {
          key: 'actions',
          label: ' ',
        }
      ],
      selected: [],
      perPage: 100,
      options: [100, 150, 200],
      currentPage: 1,
      allSelected: false
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
  },
  methods: {
    gotoMemberClicked(member_id) {
      if (member_id) {
        this.$router.push(`/ml-member-editor/${member_id}`)
      }
    },

    onRowSelected(items) {
      this.selected = items;
    },

    saveMemberClicked(item) {
      axios
        .put(`/member/${item.member_id}`, { email: item.email, phone: item.phone })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), 'Medlem uppdaterad');
        })
        .catch(err => {
          console.error(err);
        });
    },

    deleteMemberClicked(member_id) {
      this.$emit('onDeleteSubMember', this.duplicateSummaryItem.member_id, member_id)
    },

    selectRow(index) {
      if (this.$refs.duplicateSubMembersTable.isRowSelected(index)) {
        this.$refs.duplicateSubMembersTable.unselectRow(index);
      } else {
        this.$refs.duplicateSubMembersTable.selectRow(index);
      }
    },

    toggleRows() {
      if (this.allSelected) {
        this.$refs.duplicateSubMembersTable.clearSelected();
        this.allSelected = false;
      } else {
        this.$refs.duplicateSubMembersTable.selectAllRows();
        this.allSelected = true;
      }
    },


    scrollToEditDuplicate() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

  }
};
</script>
